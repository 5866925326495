import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { searchTitle } from '../../../routing/routeConfiguration';
import { flagsSeoOptions } from '../../../util/fieldsOptions';

import css from './SectionCountry.module.css';
import { getDefaultLocale } from '../../../util/genericHelpers';

const SectionCountry = props => {
  const { rootClassName, className, marketplaceRootURL } = props;

  const defaultLocale = getDefaultLocale();

  const classes = classNames(rootClassName || css.root, className);

  const renderSeoFlagsContent = () => {
    return flagsSeoOptions.map((l, i) => (
      <div
        className={css.countryBlock}
        key={i}
        onClick={() => {
          if (typeof window !== 'undefined') {
            window.location.href = `${marketplaceRootURL}/${searchTitle}${l?.geolocation}`;
          }
        }}
      >
        <img
          src={l.flag}
          alt={l.translations[defaultLocale]}
          className={css.icon}
          width="64"
          height="64"
        />
        <span>{l.translations[defaultLocale]}</span>
      </div>
    ));
  };

  return (
    <div className={classes}>
      <div className={css.countrySec}>{renderSeoFlagsContent()}</div>
    </div>
  );
};

SectionCountry.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionCountry.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionCountry;
