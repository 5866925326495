import React, { useState } from 'react';
import { useIntl } from '../../../util/reactIntl';
import { Button } from '../../../components';

import css from './CommonSections.module.css';
import { showToaster } from '../../../util/genericHelpers';
import { subscribeToNewsletter } from '../../../ducks/global.duck';
import { useDispatch } from 'react-redux';

function NewsletterSection(props) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const title = intl.formatMessage({ id: 'NewsletterSection.title' });
  const email = intl.formatMessage({ id: 'NewsletterSection.email' });
  const subscribe = intl.formatMessage({ id: 'NewsletterSection.subscribe' });
  const successMessage = intl.formatMessage({ id: 'NewsletterSection.success' });
  const errorMessage = intl.formatMessage({ id: 'NewsletterSection.error' });

  const [inProgress, setInProgress] = useState(false);
  const [ready, setReady] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async e => {
    setInProgress(true);
    e.preventDefault();
    // You can perform any actions with the form data here (e.g., submit it to a server)
    // Reset the form fields after submission
    setFormData({
      email: '',
    });
    try {
      const subscription = await dispatch(subscribeToNewsletter({ email: formData.email }));
      setInProgress(false);
      showToaster('success', successMessage);
      setReady(true);
    } catch (error) {
      showToaster('error', errorMessage);
      setInProgress(false);
    }
  };

  return (
    <div className={css.newsletterSec}>
      <h2>{title}</h2>
      <form className={css.newsletterForm} onSubmit={handleSubmit}>
        <input
          type="email"
          id="email"
          name="email"
          placeholder={email}
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <Button inProgress={inProgress} ready={ready} type="submit">
          {subscribe}
        </Button>
      </form>
    </div>
  );
}

export default NewsletterSection;
