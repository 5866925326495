import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { convertMarkdownToReact } from '../../../util/markdownHelpers';
import css from './Extras.module.css';

function SectionContent(props) {
  const { content } = props;
  const image = content?.image?.data?.attributes?.url;

  return (
    <div className={css.locationSection}>
      <div className={css.fixedWidthContainer}>
        {content?.id === 1 ? (
          <h2>
            <FormattedMessage id="SEOWrapperPage.findPitchesWithWishes" />
          </h2>
        ) : null}
        <h3>{content?.heading}</h3>
        {content?.description ? convertMarkdownToReact(content?.description) : ''}
        {image ? (
          <div className={css.imgDiv}>
            <img src={image} alt={content?.heading} className={css.sectionImages} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SectionContent;
