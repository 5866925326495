import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  Page,
  LayoutSingleColumn,
  IconSpinner,
  LandingListingCard,
  Button,
  IconNextArrow,
  IconPrevArrow,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import FooterContainer from '../../containers/FooterContainer/FooterContainer';
import { withViewport } from '../../util/uiHelpers';
import { useConfiguration } from '../../context/configurationContext';

//Static assets
import facebookImage from '../../assets/biketribe-facebook-sharing-1200x630.jpg';
import twitterImage from '../../assets/biketribe-twitter-sharing-600x314.jpg';
import { isScrollingDisabled } from '../../ducks/ui.duck';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import {
  extractSeoCategoryData,
  formatStrToQueryStr,
  isArrayLength,
  sortListingsByInfo,
} from '../../util/genericHelpers';
import { convertMarkdownToReact } from '../../util/markdownHelpers';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { decodeLatLngBounds, parse } from '../../util/urlHelpers';
import { isOriginInUse } from '../../util/search';
import { extractAndFormatBoundsFromString } from '../../util/dataExtractors';
import SeoMap from './SeoMap/SeoMap';
import Slider from 'react-slick';

import css from './SeoPage.module.css';
import '../../styles/slick/slick-theme.css';
import '../../styles/slick/slick.css';
import { essentialsOptions, parkingLocationOptions } from '../../util/fieldsOptions';
import SectionCountry from './SectionCountry/SectionCountry';
import classNames from 'classnames';
import SectionContent from './Extras/SectionContent';
import NewsletterSection from '../LandingPage/CommonSections/NewsletterSection';

function RenderNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconNextArrow className={css.icon} />
    </div>
  );
}

function RenderPrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <IconPrevArrow className={css.icon} />
    </div>
  );
}
const sliderSettings = {
  dots: false,
  arrows: true,
  nextArrow: <RenderNextArrow />,
  prevArrow: <RenderPrevArrow />,
  infinite: true,
  autoplay: false,
  centerMode: true,
  autoplaySpeed: 2000,
  speed: 500,
  slidesToShow: 5.2, // Display 4 items on desktop
  slidesToScroll: 1, // Scroll 1 item at a time
  variableWidth: false,
  focusOnSelect: false,
  responsive: [
    {
      breakpoint: 1600, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 4.5,
      },
    },
    {
      breakpoint: 1280, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 3.5,
      },
    },
    {
      breakpoint: 991, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1.8,
      },
    },
    {
      breakpoint: 580, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1.4,
      },
    },
    {
      breakpoint: 480, // Adjust settings for smaller viewports
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};
//Section pages
export const SeoPageComponent = props => {
  const {
    searchInProgress,
    seoCategory,
    intl,
    scrollingDisabled,
    history,
    viewport,
    listings,
    location,
  } = props;

  const [showMapListings, setShowMapListings] = useState(false);

  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const siteTitle = config.marketplaceName;

  const schemaImage = `${config.marketplaceRootURL}${facebookImage}`;

  const {
    title = '',
    headingImage = '',
    content = [],
    description = '',
    heading = '',
    url,
  } = extractSeoCategoryData(seoCategory);

  const parsedBounds = url ? extractAndFormatBoundsFromString(url) : null;

  const onMapMoveEnd = (viewportBoundsChanged, data) => {
    const { viewportBounds, viewportCenter } = data;

    const searchPagePath = pathByRouteName('SearchPage', routeConfiguration);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      const originMaybe = isOriginInUse(config) ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
      };

      history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    }
  };

  const bounds = parsedBounds ? decodeLatLngBounds(parsedBounds) : null;
  const popularSearchLabel = intl.formatMessage({
    id: 'SeoSectionLanding.popularSearchLabel',
  });
  const isMobileLayout = viewport.width && viewport.width > 0 && viewport.width < 768;
  const sortedListings = sortListingsByInfo([...listings]);

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={`Camping ${title}`}
      title={`Camping ${title}`}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        {
          url: `${config.marketplaceRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: `Camping ${title}`,
        name: `Camping ${title}`,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn
        showMobileNavBar
        className={css.pageRoot}
        topbar={<TopbarContainer />}
        footer={<FooterContainer showMobileNavBar />}
      >
        {searchInProgress ? (
          <div className={classNames(css.fixedWidthContainer, css.loaderDiv)}>
            <div className={css.loader}>
              <IconSpinner />
            </div>
          </div>
        ) : (
          <div className={css.container}>
            <div className={css.mainContentWrapper}>
              <div className={css.sectionHero}>
                <img src={headingImage} alt={title} />
                <h2>{intl.formatMessage({ id: 'SeoPage.campingIn' }, { title })}</h2>
              </div>
              <div className={css.seoCategory}>
                <div className={css.fixedWidthContainer}>
                  <h2>{heading}</h2>
                  {description ? convertMarkdownToReact(description) : ''}
                  <div className={css.mapWrapper}>
                    <div className={css.clusterMapWrapperDesktop}>
                      <SeoMap
                        reusableContainerClassName={css.map}
                        activeListingId={null}
                        bounds={bounds}
                        center={null}
                        isSearchMapOpenOnMobile={false}
                        location={location}
                        infoOnlyLabel={intl.formatMessage({
                          id: 'SearchPageWithMap.showBookableListings',
                        })}
                        listings={listings || []}
                        onMapMoveEnd={onMapMoveEnd}
                        onCloseAsModal={() => {}}
                        messages={intl.messages}
                      />
                    </div>
                  </div>{' '}
                </div>
              </div>
              {/* Map Listings */}
              <div className={css.mapListingSec}>
                <div className={css.mapListings}>
                  {isMobileLayout ? (
                    <div className={classNames(css.fullSliderSection, css.mobileMaplistings)}>
                      <Slider {...sliderSettings}>
                        {' '}
                        {isArrayLength(sortedListings)
                          ? (showMapListings
                              ? sortedListings.slice(0, 12)
                              : sortedListings
                            ).map(l => (
                              <LandingListingCard
                                className={css.listingCard}
                                key={l.id.uuid}
                                listing={l}
                                renderSizes={[
                                  '(max-width: 767px) 100vw',
                                  `(max-width: 1023px) ${50}vw`,
                                  `(max-width: 1920px) ${62.5 / 2}vw`,
                                  `${62.5 / 3}vw`,
                                ]}
                                setActiveListing={() => {}}
                              />
                            ))
                          : null}
                      </Slider>
                    </div>
                  ) : (
                    <div className={css.mapListingsCards}>
                      {isArrayLength(sortedListings)
                        ? (showMapListings
                            ? sortedListings.slice(0, 12)
                            : sortedListings
                          ).map(l => (
                            <LandingListingCard
                              className={css.listingCard}
                              key={l.id.uuid}
                              listing={l}
                              renderSizes={[
                                '(max-width: 767px) 100vw',
                                `(max-width: 1023px) ${50}vw`,
                                `(max-width: 1920px) ${62.5 / 2}vw`,
                                `${62.5 / 3}vw`,
                              ]}
                              setActiveListing={() => {}}
                            />
                          ))
                        : null}
                    </div>
                  )}
                  {isArrayLength(sortedListings) ? (
                    <div className={css.moreListingsBtn}>
                      <Button type="button" onClick={() => setShowMapListings(!showMapListings)}>
                        {intl.formatMessage({
                          id: showMapListings
                            ? 'SectionDetailsMaybe.showLess'
                            : 'SectionDetailsMaybe.showMore',
                        })}
                      </Button>
                    </div>
                  ) : null}
                </div>
              </div>
              {/* Most Popular Listings */}
              <div className={css.fullSliderSection}>
                {/* <Slider {...sliderSettings}>
                  {isArrayLength(sortedListings)
                    ? sortedListings.map(l => (
                        <LandingListingCard
                          className={css.listingCard}
                          key={l.id.uuid}
                          listing={l}
                          renderSizes={[
                            '(max-width: 767px) 100vw',
                            `(max-width: 1023px) ${50}vw`,
                            `(max-width: 1920px) ${62.5 / 2}vw`,
                            `${62.5 / 3}vw`,
                          ]}
                          setActiveListing={() => {}}
                        />
                      ))
                    : null}
                </Slider> */}
                <div className={css.popularAmenities}>
                  <h3 className={css.popularSearchLabel}>{popularSearchLabel}</h3>
                  <div className={css.showGeneralAmenities}>
                    {essentialsOptions
                      .filter(e =>
                        [
                          'toilet',
                          'electricity',
                          'shower',
                          'water-hookup',
                          'daily-waste-disposal',
                          'fire-pit',
                        ].includes(e.key)
                      )
                      .map((rri, i) => (
                        <div
                          key={i}
                          className={css.amenityBlock}
                          onClick={() => {
                            if (typeof window !== 'undefined') {
                              window.location.href = createResourceLocatorString(
                                'SearchPage',
                                routeConfiguration,
                                {},
                                {
                                  address: 'Europe',
                                  mapSearch: 'true',
                                  bounds: '62.33995248,19.17157943,25.98756225,-11.07927511',
                                  pub_isInfoOnly: 'false',
                                  pub_essentials: formatStrToQueryStr([rri?.key]),
                                }
                              );
                            }
                          }}
                        >
                          <img src={rri?.icon} />
                          <p>{rri?.label}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className={css.findCountrySec}>
                <div className={css.fixedWidthContainer}>
                  <div className={css.locationsWidth}>
                    <h2>
                      <FormattedMessage id="SeoPage.findPitches" />
                    </h2>
                    <SectionCountry
                      rootClassName={css.findCountryLocation}
                      marketplaceRootURL={config.marketplaceRootURL}
                    />
                  </div>{' '}
                </div>
              </div>{' '}
              <div className={css.regionHighLightsSec}>
                <div className={css.fixedWidthContainer}>
                  {isArrayLength(content)
                    ? content.map(c => <SectionContent key={c?.id} content={c} />)
                    : null}
                </div>
              </div>
              {/* Popular Parking Location */}
              <div className={css.discoverSec}>
                <div className={css.popularAmenities}>
                  <h3 className={css.popularSearchLabel}>
                    {intl.formatMessage({
                      id: 'SeoPage.discoverOtherDestinations',
                    })}
                  </h3>
                  <div className={css.showGeneralAmenities}>
                    {parkingLocationOptions
                      .filter(e =>
                        [
                          'by-a-body-of-water',
                          'on-the-edge-of-a-forest',
                          'on-a-field',
                          'in-a-forest-area',
                          'on-the-edge-of-a-field',
                          'in-a-courtyard',
                        ].includes(e.key)
                      )
                      .map((rri, i) => (
                        <div
                          key={i}
                          className={css.amenityBlock}
                          onClick={() => {
                            if (typeof window !== 'undefined') {
                              window.location.href = createResourceLocatorString(
                                'SearchPage',
                                routeConfiguration,
                                {},
                                {
                                  address: 'Europe',
                                  mapSearch: 'true',
                                  bounds: '62.33995248,19.17157943,25.98756225,-11.07927511',
                                  pub_isInfoOnly: 'false',
                                  pub_essentials: formatStrToQueryStr([rri?.key]),
                                }
                              );
                            }
                          }}
                        >
                          <img src={rri?.icon} />
                          <p>{rri?.label}</p>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div className={css.newsletterSection}>
                <NewsletterSection onSendNewsLetter={() => {}} />
              </div>
            </div>
          </div>
        )}
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool } = PropTypes;

SeoPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { searchInProgress } = state.SeoPage;
  const { seoCategory } = state.strapi;
  const { currentPageResultIds } = state.SearchPage;
  let listings = getListingsById(state, currentPageResultIds);

  if (isArrayLength(listings)) {
    listings = listings.filter(l => !l?.attributes?.publicData?.isInfoOnly);
  }

  return {
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    seoCategory,
    listings,
  };
};

const mapDispatchToProps = dispatch => ({});

const SeoPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withViewport,
  injectIntl
)(SeoPageComponent);

export default SeoPage;
